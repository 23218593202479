import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import ContactLady from '../components/contact/contact-lady-image'
import styles from './contact.module.scss'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const ContactPage = () => {
  return (
    <Layout>
      <SEO title='Contact' />

      <div className='container mx-auto flex'>
        <div className='w-full md:w-1/2 relative'>
          <div className={`block ${styles.contactLady} md:hidden`}>
            <ContactLady />
          </div>
          <div className='w-full px-4 md:px-8 mt-48 md:mt-0 mb-12'>
            <h1 className='text-2xl sm:text-4xl text-white md:text-black mb-5 lg:mt-12 xl:mt-16'>Contact</h1>
            <Formik
              initialValues={
                {
                  name:'',
                  email:'',
                  company:'',
                  country:'',
                  message:'',
                }
              }
              validationSchema={Yup.object().shape({
                email: Yup.string()
                .email()
                .required('Required')
              })}
            >
            {
              props => {

                const fieldClassName = `w-full px-3 py-2 mb-3 rounded-md ${styles.field}`;
                const messageBox = `w-full px-3 py-2 h-32 mb-3 rounded-md ${styles.field}`;

                return (
                  <form name='Contact' method='POST' data-netlify='true' className={`flex flex-col ${styles.form}`}>
                    <input type="hidden" name="form-name" value="Contact" />
                    <Field
                      className={fieldClassName}
                      type='text'
                      name='name'
                      placeholder='Name'
                    />
                    <Field
                      className={fieldClassName}
                      type='text'
                      name='email'
                      placeholder='E-mail'
                    />
                    <Field
                      className={fieldClassName}
                      type='text'
                      name='company'
                      placeholder='Company'
                    />
                    <Field
                      className={fieldClassName}
                      type='text'
                      name='country'
                      placeholder='Country'
                    />
                    <Field
                      className={messageBox}
                      as='textarea'
                      type='text'
                      name='message'
                      placeholder='Message'
                    />

                    <div className='text-center'>
                      <button type='submit' className='bg-black hover:bg-gray-800 text-white font-bold text-sm uppercase py-2 px-12 rounded-sm my-3'>Get in touch</button>
                      <p>Got question? Find answers in <Link to='/faq/' className='underline font-bold'>FAQ</Link></p>
                    </div>

                  </form>
                )
              }
            }
          </Formik>
          </div>
        </div>
        <div className='w-1/2 hidden md:block'>
          <ContactLady />
        </div>
      </div>
    </Layout>
  )
}


export default ContactPage
